const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const MS_IN_SECOND = 1000;

const ONE_HOUR_IN_MS = MS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
const ONE_MINUTE_IN_MS = MS_IN_SECOND * SECONDS_IN_MINUTE;

export { ONE_HOUR_IN_MS, ONE_MINUTE_IN_MS };

export const FEEDBACK_TYPES = [
  { label: "Todas", value: undefined },
  { label: "Análise", value: "ANALYSIS" },
  { label: "Inspeção", value: "INSPECTION" },
];
