import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import classnames from "classnames";
import { When } from "react-if";
import uuid from "react-uuid";
import { adminItems, apiItems, productItems } from "../../items";
import HeaderItem from "./HeaderItem";
import { AuthorizerStore } from "stores";
import ProfilePage from "pages/profile";
import { getCurrentUser } from "utils";
import cbdLogo from "assets/cbdpage.png";
import styles from "./styles.module.sass";

const paths = ["/forgot", "/ativar"];

const Header = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const toggle = useCallback(() => setIsOpen((v) => !v), []);
  const onClick = useCallback(() => {
    setIsOpenProfile((v) => !v);
    setIsOpen(false);
  }, []);
  const logout = () => {
    AuthorizerStore.setToken("");
    history.push("/login");
  };
  if (AuthorizerStore.authenticated && paths.includes(location.pathname)) {
    history.replace("/login");
  }
  useEffect(() => {
    !AuthorizerStore.authenticated && setIsOpenProfile(false);
  }, [AuthorizerStore.authenticated]);

  return (
    <>
      <Navbar
        color="dark"
        dark
        expand="md"
        fixed="fixed"
        className={styles.navbar}
      >
        <NavbarBrand
          role="button"
          tag={Link}
          to="/"
          onClick={() => sessionStorage.clear()}
          className="d-flex align-self-center pl-md-5"
        >
          <img src={cbdLogo} alt="logo" style={{ height: 32 }} />
        </NavbarBrand>
        <small className="pl-1 d-inline text-white">
          {process.env.VERSION}
          <span className="d-none d-md-inline" style={{ color: "transparent" }}>
            .{process.env.BUILD}
          </span>
        </small>
        <When condition={AuthorizerStore.authenticated}>
          <div className="d-md-none">
            <NavbarToggler onClick={toggle} />
          </div>
          <div className="w-100 d-flex">
            <Collapse isOpen={isOpen} navbar>
              <br className="d-md-none" />
              <Nav navbar>
                <NavItem
                  className={classnames(["pl-2 pl-md-0", styles.profile])}
                >
                  <NavLink tag={Link} onClick={onClick}>
                    <FaUserCircle size={25} />
                    <span>&nbsp;{getCurrentUser()?.GivenName}</span>
                  </NavLink>
                </NavItem>
                {productItems.map((item) => (
                  <HeaderItem
                    key={`item_${uuid()}`}
                    {...item}
                    onClick={toggle}
                  />
                ))}
                {apiItems.map((item) => (
                  <HeaderItem
                    key={`item_${uuid()}`}
                    {...item}
                    onClick={toggle}
                  />
                ))}
                {adminItems.map((item) => (
                  <HeaderItem
                    key={`item_${uuid()}`}
                    {...item}
                    onClick={toggle}
                  />
                ))}
                <HeaderItem title="Logout" icon={MdLogout} onClick={logout} />
              </Nav>
            </Collapse>
          </div>
        </When>
      </Navbar>
      <ProfilePage isOpen={isOpenProfile} toggleProfile={onClick} />
    </>
  );
});

export default Header;
