import qs from "qs";
import { formatDate } from "../utils";
import Api, { get, save } from "./Api";
import QueryString from "qs";

export default class IssueApi extends Api {
  constructor() {
    super(process.env.REACT_APP_ISSUE_ENDPOINT);
  }

  async getCapture(id, type = "STREAM") {
    const additionalParamsQuery = QueryString.stringify(
      { size: 50 },
      { addQueryPrefix: true }
    );
    const { content, totalElements } = await get(
      `${this.baseUrl}/${id}/captures${additionalParamsQuery}`
    );

    switch (type) {
      case "STREAM":
        return content[0];
      case "PAIR":
        return content;
      default:
        return { content, totalElements };
    }
  }

  getPendencies = (
    page,
    pageSize,
    company,
    user,
    plate,
    startDate,
    finishDate,
    feedbackType
  ) => {
    const query = qs.stringify(
      {
        startDate: formatDate(startDate),
        finishDate: formatDate(finishDate),
        page: page || "",
        size: pageSize,
        email: user,
        plate,
        company,
        feedbackType,
      },
      { filter: (_, value) => value || undefined }
    );
    return this.fetch(query);
  };

  download = async (filename, filter) => {
    const response = await get(`${this.baseUrl}/export?${filter}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "text/csv",
      },
    });
    save(response, filename, "text/csv");
  };
}
