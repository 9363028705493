import React, { useState, useCallback } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { DefaultInput } from "..";
import styles from "./styles.module.sass";

/**
 * Componente de input de senha com toggle de visibilidade
 * Sabemos que o componente de senha deveria ser do tipo "password", porém usamos o formato atual com estilização do 
 * input via CSS como uma forma de "burlar" o fluxo de salvamento de senha do navegador, e evitar que o usuário salve a 
 * senha.
 * 
 * @component
 * @param {Function} props.onChange - Callback para mudanças na senha
 * @param {string} props.error - Mensagem de erro a ser exibida
 * @param {string} props.className - Classe CSS adicional
 * @param {string} props.feedbackMessage - Mensagem de feedback a ser exibida
 */
const PasswordInput = ({
  onChange,
  error,
  className,
  feedbackMessage,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsActive, setCapsActive] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleCapsLock = useCallback((event) => {
    if ("getModifierState" in event) {
      setCapsActive(event.getModifierState("CapsLock"));
    }
  }, []);

  const ToggleIcon = showPassword ? LuEyeOff : LuEye;
  const capsLockText = isCapsActive ? "Caps Lock ativo" : "";

  return (
    <div className={styles.togglerContainer}>
      <DefaultInput
        {...props}
        data-testid="password-input"
        type="text"
        className={classnames(className, {
          [styles["input-password"]]: !showPassword
        })}
        error={error}
        feedbackMessage={feedbackMessage}
        helperText={capsLockText}
        autoComplete="new-password"
        onChange={onChange}
        onKeyUp={handleCapsLock}
        onMouseDown={handleCapsLock}
        placeholder="Senha"
        rightIcon={
          <button
            type="button"
            onClick={toggleShowPassword}
            className={styles.toggleButton}
            aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
          >
            <ToggleIcon color="#E14E0A" size={24} />
          </button>
        }
      />

      <Link to="/forgot">Esqueci minha senha</Link>
    </div>
  );
};

export default PasswordInput;
