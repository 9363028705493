const enumValue = (value, label) => Object.freeze({ label, value });

const JUNK_YARD = enumValue('JUNK_YARD', 'Área de Desmanche');
const RACETRACK = enumValue('RACETRACK', 'Autódromo');
const PARKING = enumValue('PARKING', 'Estacionamento');
const FRONTIER = enumValue('FRONTIER', 'Fronteira'); 
const IMPOUND_LOT = enumValue('IMPOUND_LOT', 'Pátios');
const HIGHWAY = enumValue('HIGHWAY', 'Rodovia');
const OTHER = enumValue('OTHER', 'Outro');

export const values = () => [
    JUNK_YARD,
    RACETRACK,
    PARKING,
    FRONTIER,
    IMPOUND_LOT,
    HIGHWAY,
    OTHER
];