import React, { memo } from 'react'
import { IoCloseCircleOutline } from "react-icons/io5"
import welcomeLogo from 'assets/welcome-logo.svg'
import welcomeBkg from 'assets/welcome-bg.png'
import { useEnvVersion } from 'hooks/useEnvVersion'
import BaseModal from '../BaseModal'
import styles from './styles.module.sass'

/**
 * Modal de boas-vindas que é exibido quando há uma atualização de versão
 * @component
 * @param {Object} props - Propriedades do componente
 * @returns {React.ReactElement} Componente Modal de boas-vindas
 */
const WelcomeModal = memo(() => {
   const [modalOpen, handleToggle] = useEnvVersion()

   const customCloseButton = (
      <IoCloseCircleOutline
         size={24}
         onClick={handleToggle}
         aria-label="Close modal"
      />
   )

   return (
      <BaseModal
         isOpen={modalOpen}
         toggle={handleToggle}
         size="lg"
         className={styles.welcomeModalContainer}
         backdropClassName={styles.backdrop}
         contentClassName={styles.welcomeModalContent}
         data-testid="welcome-modal"
         headerClassName={styles.welcomeModalHeader}
         customCloseButton={customCloseButton}
      >
         <div className={styles.welcomeModalBody}>
            <img src={welcomeLogo} alt="Car BigData Logo" />
            <h2 className={styles.bodyTitle}>
               Nossa nova marca está no ar!
            </h2>
            <div className={styles.bodyContent}>
               <p>
                  Agora, o design da plataforma está atualizado! Essa mudança reflete
                  o nosso crescimento e evolução.{" "}
                  <strong>Aproveite o novo visual!</strong>
               </p>
            </div>
         </div>

         <img
            src={welcomeBkg}
            alt="Background decoration"
            className={styles.welcomeModalBkg}
         />
      </BaseModal>
   )

})

WelcomeModal.displayName = 'WelcomeModal'

export default WelcomeModal