import React from "react";
import { Button } from "reactstrap";
import { When } from "react-if";
import BaseModal from "./BaseModal";

const ConfirmModal = ({
  title = "Atenção!",
  toggle,
  isOpen,
  confirm,
  size,
  className,
  confirmLabel = "Sim",
  cancelLabel = "Não",
  hideCancel = false,
  confirmDisabled = false,
  hideConfirm = false,
  children,
  ...rest
}) => {
  const footer = (
    <>
      <When condition={!hideConfirm}>
        <Button onClick={confirm} color="primary" disabled={confirmDisabled}>
          {confirmLabel}
        </Button>
      </When>
      <When condition={!hideCancel}>
        <Button onClick={toggle}> {cancelLabel} </Button>
      </When>
    </>
  );

  return (
    <BaseModal
      title={title}
      toggle={toggle}
      isOpen={isOpen}
      footer={footer}
      className={className}
      {...rest}
    >
      {children}
    </BaseModal>

  );
};

export default ConfirmModal;
