import React from "react";
import classnames from "classnames";
import { Badge } from "reactstrap";
import { When } from "react-if";
import styles from "./styles.module.sass";

/**
 * @typedef {Object} NotificationBadgeProps
 * @property {number} count Número de notificações a serem exibidas
 * @property {string} [className] Classes CSS adicionais
 */

/**
 * Mostra um badge de notificação com o número de notificações
 * @component
 * @param {NotificationBadgeProps} props Propriedades do componente
 * @returns {JSX.Element} Componente de badge de notificação
 */
const NotificationBadge = ({ count, className }) => {
  return (
    <When condition={count}>
      <Badge
        className={classnames([styles.close, styles.badge, className])}
        color="danger"
      >
        {count}
      </Badge>
    </When>
  );
};

export default NotificationBadge;
