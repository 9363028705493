import React from "react";
import ReactSelect from "react-select";
import { FormGroup, Label } from "reactstrap";
import { defaultTheme } from "../../styles/themes/default";

const cbdcColors = {
  primary: defaultTheme["primary-100"],
  primary25: defaultTheme["primary-25"],
  primary50: defaultTheme["primary-50"],
  primary75: defaultTheme["primary-75"],
};

/** @property {string} [label] - Optional label text to display above select
 * @property {boolean} [error] - Whether the select is in an error state
 * @property {Object} [styles] - Custom styles to merge with default styles
 * @property {Array} options - Array of options objects with value and label
 * @property {Function} onChange - Callback fired when selection changes
 * @property {any} value - Currently selected value(s)
 * @property {boolean} [isMulti] - Whether multiple selections are allowed
 * @property {boolean} [isSearchable] - Whether to enable search functionality
 * @property {string} [placeholder] - Placeholder text when no selection
 */
const Select = ({ id, label, error, ...props }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: defaultTheme["primary-100"],
      backgroundColor: defaultTheme["neutral-0"],
      borderColor: error
        ? defaultTheme["error-100"]
        : state.isFocused
        ? defaultTheme["primary-50"]
        : defaultTheme["neutral-30"],
      boxShadow: state.isFocused
        ? `0 0 0 0.2rem ${defaultTheme["primary-25"]}`
        : "none",
      "&:hover": {
        borderColor: error
          ? defaultTheme["error-100"]
          : state.isFocused
          ? defaultTheme["primary-50"]
          : defaultTheme["neutral-30"],
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: defaultTheme["primary-100"],
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
      transition: "transform 0.2s ease-in-out",
      "&:hover": {
        color: defaultTheme["primary-50"],
      },
    }),
    menuList: (base) => ({
      ...base,
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: defaultTheme["primary-50"],
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: defaultTheme["primary-75"],
      },
    }),
    ...props.styles,
  };
  return (
    <FormGroup className="select-wrapper" data-testid="form">
      {label && <Label htmlFor={id}>{label}</Label>}
      <ReactSelect
        {...props}
        inputId={id}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...cbdcColors,
          },
        })}
      />
    </FormGroup>
  );
};

export default Select;
