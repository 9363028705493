import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { FaStar, FaCheck } from "react-icons/fa";
import { When } from "react-if";
import classnames from "classnames";
import styles from "./style.module.sass";

const SelectedTagBadge = ({ tag }) => (
  <When condition={!!tag}>
    <Badge
      color="warning"
      className={styles.tagBadge}
    >
      {tag?.name}
    </Badge>
  </When>
);

const DropdownItems = ({ tags, selectedTag, setSelectedTag }) => (
  <>
    <DropdownItem className={styles.dropdownItem} onClick={() => setSelectedTag(null)} active={!selectedTag}
    >
      <span className="nav-link">
        <FaCheck color="green" />
      </span>
    </DropdownItem>
    {tags.map((tag) => (
      <DropdownItem
        key={tag.id}
        className={styles.dropdownItem}
        onClick={() => setSelectedTag(tag)}
        active={selectedTag === tag}
      >
        <span className="nav-link">{tag.name}</span>
      </DropdownItem>
    ))}
  </>
);

const StarIcon = ({ selectedTag, onClick }) => (
  <FaStar className={styles.starIcon} size="22" color={!selectedTag ? "#ddd" : "#ee0"} onClick={onClick} />
);

const SpotlightDropdown = ({
  popup = false,
  setSelectedTag,
  selectedTag,
  tags,
  reverse = false,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((v) => !v);

  const contentClasses = classnames(styles.content, {
    [styles.reverse]: reverse,
    [styles.popup]: popup
  });

  return (
    <div className={styles.container}>
      <div className={contentClasses}>
        <SelectedTagBadge tag={selectedTag} />

        <div className={styles.dropdownWrapper}>
          <StarIcon
            selectedTag={selectedTag}
            onClick={toggle}
          />

          <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            direction="down"
            className={styles.dropdown}
            style={{ display: isOpen ? "block" : "none" }}
          >
            <DropdownToggle
              color="transparent"
              className={styles.hiddenToggle}
            />
            <DropdownMenu
              className={classnames({
                [styles.dropdownMenu]: !popup
              })}
              container="body"
            >
              <DropdownItems
                tags={tags}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SpotlightDropdown;