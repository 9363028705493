const enumValue = (description, label) => Object.freeze({
    toString: () => description,
    label
});

export const FRONT = enumValue('FRONT', 'Frente');
export const FRONT_LEFT = enumValue('FRONT_LEFT', 'Frente Esquerda');
export const FRONT_RIGHT = enumValue('FRONT_RIGHT', 'Frente Direita');

export const REAR = enumValue('REAR', 'Traseira');
export const REAR_LEFT = enumValue('REAR_LEFT', 'Traseira Esquerda');
export const REAR_RIGHT = enumValue('REAR_RIGHT', 'Traseira Direita');

const BORDER_HEADING_BRAZIL = enumValue('BORDER_HEADING_BRAZIL','Fronteira ➡️ Brasil');
const BORDER_HEADING_MERCOSUL = enumValue('BORDER_HEADING_MERCOSUL', 'Fronteira ➡️ Mercosul');

export const values = () => [
    FRONT,
    FRONT_LEFT,
    FRONT_RIGHT,
    REAR,
    REAR_LEFT,
    REAR_RIGHT,
    BORDER_HEADING_BRAZIL,
    BORDER_HEADING_MERCOSUL
];

export const find = value => values().find(side => side.toString() === value);
