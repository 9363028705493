import React from "react";
import { FormGroup, Label, UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-date-picker";
import classnames from "classnames";
import "./styles.sass";

const CustomDatePicker = ({
  datePick,
  onChangeDate,
  labelText,
  maxDate,
  minDate,
  required,
  disabled,
  icon: Icon,
  tooltipId,
  tooltipText,
  invalid,
  errorMessage,
  className,
  oldVersion = true,
}) => {
  if (oldVersion) {
    return (
      <FormGroup
        disabled={disabled}
        className={classnames([
          "date-input-old",
          className,
          { disabled: disabled },
        ])}
      >
        <Label
          for="date"
          style={{ color: "#6c757d" }}
          data-testid="date-picker-label"
        >
          {labelText}:&nbsp;
        </Label>
        <DatePicker
          value={datePick}
          onChange={onChangeDate}
          locale="pt-BR"
          calendarIcon={null}
          showLeadingZeros={true}
          data-testid="date-picker"
          clearIcon={null}
          minDate={minDate || undefined}
          disabled={disabled}
          {...{ maxDate, required }}
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup className="date-input" data-testid="date-input">
      <Label
        className={classnames("form-control-label", {
          "text-danger": invalid && !disabled,
          "text-muted": disabled,
        })}
        data-testid="date-picker-label"
      >
        {labelText}
        {Icon && (
          <>
            <Icon id={tooltipId} size={16} style={{ color: "#404243" }} />
            {tooltipText && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                {tooltipText}
              </UncontrolledTooltip>
            )}
          </>
        )}
      </Label>

      <DatePicker
        value={datePick}
        onChange={onChangeDate}
        locale="pt-BR"
        showLeadingZeros
        clearIcon={null}
        calendarIcon={null}
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        disabled={disabled}
        className={classnames({
          "is-invalid": invalid && !disabled,
          "is-disabled": disabled,
        })}
        data-testid="date-picker"
      />
      {invalid && !disabled && errorMessage && (
        <div className="invalid-feedback" data-testid="error-message">
          {errorMessage}
        </div>
      )}
    </FormGroup>
  );
};

export default CustomDatePicker;
