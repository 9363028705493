import { useState, useCallback, useEffect } from "react";

/** @constant {string} */
const VERSION_STORAGE_KEY = "ENV_VERSION";

/**
 * Hook para checar a versão do sistema e mostrar mensagem de boas vindas
 * @returns {[boolean, () => void]}
 */
export const useEnvVersion = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const compareVersions = useCallback((lastVersion, currentVersion) => {
    const [, lastMinor, lastPatch] = lastVersion.split(".").map(Number); // 1.0.0
    const [, currentMinor, currentPatch] = currentVersion
      .split(".")
      .map(Number); // 1.0.0

    return (
      currentMinor === 73 &&
      (currentMinor > lastMinor ||
        (currentMinor === lastMinor && currentPatch > lastPatch))
    );
  }, []);

  useEffect(() => {
    const lastSeenVersion = localStorage.getItem(VERSION_STORAGE_KEY);
    const currentVersion = process.env.VERSION;

    if (!currentVersion || !lastSeenVersion) {
      currentVersion &&
        localStorage.setItem(VERSION_STORAGE_KEY, currentVersion);
      return;
    }

    if (compareVersions(lastSeenVersion, currentVersion)) {
      setModalOpen(true);
      localStorage.setItem(VERSION_STORAGE_KEY, currentVersion);
    }
  }, [compareVersions]);

  const handleToggle = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, []);

  return [modalOpen, handleToggle];
};
