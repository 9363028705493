import React from "react";
import { Input, FormFeedback, Label, FormGroup, FormText } from "reactstrap";
import classnames from "classnames";
import styles from "./styles.module.sass";

/**
 * Reusable Input component with optional right icon and error/helper text
 * @component
 * @param {string} props.error - Mensagem de erro a ser exibida abaixo do input
 * @param {string} props.feedbackMessage - Mensagem de feedback a ser exibida abaixo do input
 * @param {string} props.className - Classe CSS customizada a ser aplicada ao input
 * @param {string} props.label - Título do input
 * @param {string} props.helperText - Texto de ajuda a ser exibido abaixo do input
 * @param {ReactNode} props.rightIcon - Icone a ser exibido à direita do input
 */
const DefaultInput = ({
  error,
  feedbackMessage = "",
  className,
  label,
  helperText = "",
  rightIcon,
  ...props
}) => {
  return (
    <FormGroup autoComplete="off" className={styles.inputContainer}>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <div className={styles.inputWrapper}>
        <Input
          id={props.name}
          className={classnames(styles.input, className)}
          invalid={!!error}
          autoComplete="off"
          {...props}
        />
        {rightIcon}

        {!!feedbackMessage && <FormFeedback>{feedbackMessage}</FormFeedback>}
        {!!helperText && !feedbackMessage && <FormText>{helperText}</FormText>}
      </div>
    </FormGroup>
  );
};

export default DefaultInput;
